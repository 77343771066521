<template>
<div class="tournament tournament-front">
      <b-row class="text-center text-sm-left">
            <b-col cols="12" sm="3">
                  <b-img class="icon" :src="tournamentImage"></b-img>
            </b-col>
            <b-col class="mt-3 mt-sm-0" cols="12" sm="9">
                  <h4>{{tournament.name}}</h4>
                  <p class="font-italic d-none d-sm-block">{{tournament.description}}</p>
            </b-col>
      </b-row>
</div>
</template>

<script>

export default {
      name: 'TournamentFront',
      props: ["tournament"],
      components: {},
      computed: {           
            tournamentImage() {
                  return this.$images.tournamentImage(this.tournament.uuid,this.tournament.imgversion);
            }
      },
      methods: {}
};
</script>
